import {Controller} from 'stimulus'
import classie from '../vendors/classie'
import {debounce, fetchWithController} from '../vendors/debounce'
import tmpl from 'blueimp-tmpl'
export default class extends Controller {
  connect() {
    const locale = window.locale || 'en'
    const root = document.documentElement
    const body = document.body
    const openSearchButton = document.getElementById('openSearchButton')
    let morphSearch = document.getElementById('morphsearch')
    let input = morphSearch.querySelector('input.morphsearch-input')
    let ctrlClose = morphSearch.querySelector('span.morphsearch-close')
    let isAnimating = false
    let isOpen = isAnimating
    // show/hide search area
    const toggleSearch = function (e) {
      // return if open and the input gets focused
      if (e.type.toLowerCase() === 'focus' && isOpen) return false

      // var offsets = morphsearch.getBoundingClientRect();
      if (isOpen) {
        classie.remove(morphSearch, 'open')
        classie.remove(root, 'morph-search-open')

        if (input.value !== '') {
          setTimeout(function () {
            classie.add(morphSearch, 'hideInput')
            setTimeout(function () {
              classie.remove(morphSearch, 'hideInput')
              input.value = ''
            }, 300)
          }, 500)
        }

        input.blur()
      } else {
        classie.add(morphSearch, 'open')
        classie.add(root, 'morph-search-open')
        input.focus()
      }
      isOpen = !isOpen
    }

    // events
    // input.addEventListener('focus', toggleSearch)
    openSearchButton.addEventListener('click', toggleSearch)
    ctrlClose.addEventListener('click', toggleSearch)

    document.addEventListener('keydown', (e) => {
      const keyCode = e.keyCode || e.which
      if (keyCode === 27 && isOpen) {
        toggleSearch(ev)
      }
    })

    input.addEventListener('keyup', (e) => {
      // const keyCode = e.keyCode || e.which
      const query = e.target.value
      if (query?.length > 2) {
        processFetch(query)
      }
    })

    const processFetch = debounce((query) => fetchSearch(query))

    let request
    const fetchSearch = async (query) => {
      try {
        if (request?.controller) {
          request.controller.abort()
        }
        request = await fetchWithController(
          `/${locale}/products/search.json?q=${query}`
        )
          .then((response) => response.json())
          .then((data) => render(data))
        return request
      } catch (error) {
        console.warn(error)
      }
    }

    morphSearch.querySelector('form').addEventListener('submit', (e) => {
      e.preventDefault()
    })
    /*
 * categories: [
    {
      "name": "Led Lamp",
      "url": "/it/products/c/led-lamp-135eeb4c-1d82-4982-8ada-12caa71677b8",
      "image": "/system/uploads/image/data/5022/col6_noimg.jpg",
      "product_family_name": null,
      "crumbs": [
        "Led Lamp"
      ],
      "crumbs_s": "Led Lamp"
    }]
  "products: [
    {
      "sku": "EFSA-1AE1-",
      "category_name": "Federal 3W",
      "categories_name": [
        "Opto-Elettronica",
        "Power Leds",
        "Federal Series",
        "Federal 3W"
      ],
      "url": "/it/products/efsa-1ae1",
      "image": "/system/uploads/image/data/3197/col6_Federal_Series.jpg",
      "supplier_logo": "/system/uploads/supplier/logo/3/col6_Edison.png",
      "supplier_name": "Edison",
      "product_family_name": "Opto-Elettronica",
      "crumbs": [
        "Opto-Elettronica",
        "Edison",
        "Power Leds",
        "Federal Series",
        "Federal 3W"
      ],
      "crumbs_s": "Opto-Elettronica -> Edison -> Power Leds -> Federal Series -> Federal 3W",
      "compatibility": null
    }]
 */
    const render = (data) => {
      const {showcases, suppliers, categories, products} = data
      let renderCategories, renderProducts, renderSuppliers, renderShowcases
      if (showcases.length > 0) {
        renderShowcases = tmpl(
          `<div class="col-md-4 categories">
          <h3>Articles ({%= o.length %})</h3>
          <ul class="search-result px-0 mx-0">
            {% for (var i=0; i<o.length; i++) { %}
              <li class="my-2 bg-white px-2 py-2">
                <a href="{%=o[i].url%}" data-turbolinks="false" class="d-flex">
                  <img src="{%= o[i].image %}" class="icon me-3" />
                  <div class="d-flex flex-column">
                    <span class="name">{%= o[i].name %}</span>
                    <span class="crumbs">
                    {% for (var k=0; k<o[i].crumbs.length; k++) { %}
                    <em>{%=o[i].crumbs[k]%}</em>
                    {% } %}
                  </span>
                  </div>
                </a>
                </li>
            {% } %}
          </ul>
        </div>`,
          showcases
        )
      }
      if (suppliers.length > 0) {
        renderSuppliers = tmpl(
          `<div class="col-md-4 categories">
          <h3>Partners ({%= o.length %})</h3>
          <ul class="search-result px-0 mx-0">
            {% for (var i=0; i<o.length; i++) { %}
              <li class="my-2 bg-white px-2 py-2">
                <a href="{%=o[i].url%}" data-turbolinks="false" class="d-flex">
                  <img src="{%= o[i].image %}" class="icon me-3" />
                  <div class="d-flex flex-column">
                    <span class="name">{%= o[i].name %}</span>
                    <span class="crumbs">
                    {% for (var k=0; k<o[i].crumbs.length; k++) { %}
                    <em>{%=o[i].crumbs[k]%}</em>
                    {% } %}
                  </span>
                  </div>
                </a>
                </li>
            {% } %}
          </ul>
        </div>`,
          suppliers
        )
      }
      if (categories.length > 0) {
        renderCategories = tmpl(
          `<div class="col-md-4 categories">
          <h3>Categories ({%= o.length %})</h3>
          <ul class="search-result px-0 mx-0">
            {% for (var i=0; i<o.length; i++) { %}
              <li class="my-2 bg-white px-2 py-2">
                <a href="{%=o[i].url%}" data-turbolinks="false" class="d-flex">
                  <img src="{%= o[i].image %}" class="icon me-3" />
                  <div class="d-flex flex-column">
                    <span class="name">{%= o[i].name %}</span>

                    {% if (o[i].product_family_name) { %}
                      <span class="family-name">{%= o[i].product_family_name %}</span>
                    {% } %}
                    <span class="crumbs">
                    {% for (var k=0; k<o[i].crumbs.length; k++) { %}
                    <em>{%=o[i].crumbs[k]%}</em>
                    {% } %}
                  </span>
                  </div>
                </a>
                </li>
            {% } %}
          </ul>
        </div>`,
          categories
        )
      }
      console.log(products)
      if (products.length > 0) {
        renderProducts = tmpl(
          `<div class="col-md-4 products">
        <h3>Products ({%= o.length %})</h3>
        <ul class="search-result px-0 mx-0">
          {% for (var i=0; i<o.length; i++) { %}
            <li class="my-2 bg-white px-2 py-2">
              <a href="{%=o[i].url%}" data-turbolinks="false" class="d-flex">
                <img src="{%= o[i].image %}" class="icon me-3" />
                <div class="d-flex flex-column">
                  <span class="name">{%= o[i].sku %}</span>
                  <span class="name text-dark">{%= o[i].category_name %}</span>
                  {% if (o[i].product_family_name) { %}
                    <span class="family-name">{%= o[i].product_family_name %}</span>
                  {% } %}
                  <span class="crumbs">
                  {%= o[i].crumbs_s %}
                </span>
                </div>
              </a>
              </li>
          {% } %}
        </ul>
      </div>`,
          products
        )
      }
      if (products.length === 0 && categories.length === 0 && showcases.length === 0 && partners.length === 0) {
        renderCategories = tmpl(
          `<div class="col-md-12 categories">
          <h3>Nessun risultato</h3>
        </div>`,
          {}
        )
      }
      document.getElementById('morphsearch-content').innerHTML = [
        renderShowcases,
        renderCategories,
        renderProducts,
        renderSuppliers,
      ].join('')
    }
  }
}
