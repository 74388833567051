// import Swiper JS
import Swiper from 'swiper'
import SwiperCore, {Navigation, Pagination} from 'swiper/core'
import {Controller} from 'stimulus'

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination])

export default class extends Controller {
  connect() {
    new Swiper('.my-swiper-slides-controller', {
      loop: true,
      pagination: {
        el: '.slideshow-pagination',
      },
      navigation: {
        nextEl: '.slideshow-button-next',
        prevEl: '.slideshow-button-prev',
      },
    })
  }
}
