// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

// window.iziToast = require('izitoast');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '../vendors/bootstrap-turbolinks'

import TomSelect from 'tom-select'

import SVGInject from '@iconfu/svg-inject'

// import 'mmenu-js/dist/wrappers/bootstrap/mmenu.bootstrap'
// import 'mmenu-js/dist/addons/fixedelements/mmenu.fixedelements.js'

// import 'blueimp-tmpl'

document.addEventListener('turbolinks:load', (e) => {
  SVGInject(document.getElementsByClassName('import-svg'));
  // document.getElementById("main").focus();

})

document.addEventListener('turbolinks:before-visit', function (event) {
  const url = new URL(event.data.url)
  if (url?.pathname !== '/') {
    if ($?.fn?.multiscroll?.destroy) {
      $.fn.multiscroll.destroy()
      $('#multiscrollContainer').multiscroll.destroy()
    }
  }
})

// document.addEventListener('DOMContentLoaded', () => {})

import 'controllers'
