// import Swiper JS
import Swiper from 'swiper'
import SwiperCore, {Navigation, Pagination} from 'swiper/core'
import {Controller} from 'stimulus'

// configure Swiper to use modules
SwiperCore.use([Pagination])

export default class extends Controller {
  connect() {
    new Swiper('.other-partners-slides', {
      loop: true,
      // pagination: {
      //   el: '.other-partners-pagination',
      // },
      navigation: {
        nextEl: '.other-partners-button-next',
        prevEl: '.other-partners-button-prev',
      },
      slidesPerView: 1,
      spaceBetween: 15,
      // Responsive breakpoints
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 15,
        },
      },
    })
  }
}
