import bootstrap from '/ws-frontend/node_modules/bootstrap/dist/js/bootstrap.bundle'
/**
 * Richiede data-bs-toggle="dropdown"
 */
export default () => {
  document.addEventListener('turbolinks:load', () => {
    ;[].slice
      .call(document.querySelectorAll('.dropdown-toggle'))
      .map((dropdownToggleEl) => {
        return new bootstrap.Dropdown(dropdownToggleEl)
      })
  })
}
