import 'multiscroll.js/dist/jquery.multiscroll'
import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    const idElement = 'multiscrollContainer'
    if (document.getElementById(idElement)) {
      $(`#${idElement}`).multiscroll({
        licenseKey: 'F4F6CB44-43264B16-A67A14F8-700A7F31',
        navigation: true,
        navigationTooltips: [],
        paddingTop: '55px',
        paddingBottom: '94px',
        css3: true,
        // onLeave: function (index, nextIndex, direction) {
        //   console.log(
        //     'onLeave: index:' +
        //       index +
        //       ' nextIndex:' +
        //       nextIndex +
        //       ' direction:' +
        //       direction
        //   )
        //   if (index === 1) {
        //     $('.section1').addClass('hideOverflow')
        //     $('body').removeClass('section1-loaded')
        //   }
        // },
        // afterLoad: function (anchorLink, index) {
        //   if (index === 1) {
        //     $('.section1').removeClass('hideOverflow')
        //     $('body').addClass('section1-loaded')
        //   }
        // },
        // afterRender: function () {
        //   $('body').addClass('section1-loaded')
        // },
      })
      $('#multiscrollContainer .next-top').on('click', () => {
        $.fn.multiscroll.moveSectionUp()
      })
      $('#multiscrollContainer .next-bottom').on('click', () => {
        $.fn.multiscroll.moveSectionDown()
      })
    }
  }
}
