// import Swiper JS
import Swiper from 'swiper'
import SwiperCore, {Navigation, Pagination, EffectCube} from 'swiper/core'
import {Controller} from 'stimulus'

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, EffectCube])
// import Swiper styles

export default class extends Controller {
  connect() {
    window.scrollTo(0, 1);
    new Swiper('.home-slides', {
      effect: 'cube',
      focusableElements: 'input, select, option, textarea, button, video, label, .btn-goto',
      loop: true,
      grabCursor: true,
      cubeEffect: {
        shadow: false,
        slideShadows: false,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      pagination: {
        el: '.swiper-pagination.for-home',
      },
    })
  }
}
