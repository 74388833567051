/**
 * simple debounce function
 * @param {*} func
 * @param {*} timeout
 * @returns
 */
export function debounce(func, timeout = 500) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

// Fetch and return the promise with the abort controller as controller property
export function fetchWithController(input, init = {}) {
  // create the controller
  const controller = new AbortController()
  // use the signal to hookup the controller to the fetch request
  const {signal} = controller
  // extend arguments
  init = Object.assign({signal}, init)
  // call the fetch request
  const promise = fetch(input, init)
  // attach the controller
  promise.controller = controller
  return promise
}
