import {Controller} from 'stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {

  connect() {
    // let select querySelectorAll('input');
    let opt = JSON.parse(this.element.dataset.options);
    new TomSelect(this.element,{
      delimiter: '§',
      options: opt,
      maxItems: 1,
      plugins: {
        'restore_on_backspace':{},
      },
      create: true
    });
  }
}

//
// window.initProductProductOptionsSelect = function(){
//   $(".select2-product_option").each(function(){
//     var el = $(this);
//     var opt = el.data("options");
//
//     // debugger;
//     new TomSelect(el,{
//       delimiter: '§',
//       options: opt,
//       maxItems: 1,
//     	plugins: {
//     	  'restore_on_backspace':{},
//       },
//     	create: true
//     });
//     // el.selectize({
//     //   delimiter: '§',
//     //   maxItems: 1,
//     //   options: opt,
//     //   create: true
//     // });
//   });
// };
