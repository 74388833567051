// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {Controller} from 'stimulus'
import classie from '../vendors/classie'

import 'mmenu-js/dist/mmenu'
// import 'mmenu-js/dist/wrappers/turbolinks/mmenu.turbolinks.js'

export default class extends Controller {
  connect() {
    const menu = new Mmenu(
      '#mainmenumobile',
      {
        // wrappers: ['turbolinks'],
        extensions: [
          'position-front',
          'position-right',
          'fullscreen',
          'border-full',
          'fx-listitems-slide',
          'fx-panels-slide-100',
          // "theme-dark"
        ],
        navbar: {},
        navbars: {
          content: ['prev', 'title'],
        },
        offCanvas: {
          pageSelector: '#page-wrapper',
        },
        classNames: {
          fixedElements: {
            fixed: 'fixed-element',
          },
        },
      },
      {
        classNames: {
          fixedElements: {
            fixed: 'fixed-element',
          },
        },
      }
    )

    const api = menu.API
    document
      .querySelector('#open-main-menu')
      .addEventListener('click', (evnt) => {
        if (classie.has(document.body, 'mm-wrapper_opened')) {
          api.close()
        } else {
          api.open()
        }
        evnt.preventDefault()
      })

    var elem = document.querySelectorAll('.mm-menu a');
    for(let i = 0; i < elem.length; i++){
    if (!elem[i].getAttribute("href")) {
    elem[i].href="#";
    // console.log(elem[i]);
    }
    }

  }
}
