import TomSelect from 'tom-select/dist/js/tom-select.complete'

import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {

    $(".filter-form select").change(function(){
      $(".form.filter-form").submit();
      // let url = $(".form.filter-form").attr("action");
      //
      // fetch("/test")
      // .then((data) => data.text())
      // .then((html) => {
      //   const results = document.querySelector("#results");
      //   results.insertAdjacentHTML("beforeend", data);
      // });
    })

    const items = [
      ...document.getElementsByClassName('tomselect-product_option'),
    ]
    if (items.length > 0) {
      items.forEach((el) => {
        new TomSelect(el, {
          delimiter: '§',
          maxItems: 1,
          plugins: ['restore_on_backspace', "remove_button"],
          // create: true,
        })
      })
    }
  }
}
