// import Swiper JS
// import Swiper from 'swiper'
// import SwiperCore, {Navigation, Pagination, EffectCube} from 'swiper/core'
import { linkImageLightbox, linkIframeLightbox } from 'esm-basiclightbox';
import {Controller} from 'stimulus'


export default class extends Controller {
  connect() {
    // debugger;
    var class_name = "lightbox-"+(Math.floor(Math.random() * (1000 - 0)) + 1000);
    this.element.classList.add(class_name);
    linkImageLightbox("."+class_name);
    // linkImageLightbox('.js-lightbox-image');
  }
}
